body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edf8db;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  padding: 0.5em;
  max-width: 800px;
  margin: 0 auto;
}

.meme-source {
  box-sizing: border-box;
  width: 100%;
  font-size: 20px;
  padding: 0.5em;
  margin-bottom: 0.5em;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border: 1px #3b3b3b solid;
  border-radius: 3px;
}


.stealing-buttons-wrapper {
  display: flex;
  gap: 0.5em;
}

.stealing-button {
  font-size: 20px; 
  width: 100%;
  padding: 0.4em;
  border: 1px #3b3b3b solid;
  border-radius: 3px;
}

.meme-stealer {
  background-color: rgb(172, 221, 249);
}

.error-form textarea {
  color: #800000;
  border-color: #800000;
  background-color: #ffefef;
}

.error {
  margin: 1em 0;
  color: #a20000;
}